import React from "react";

export default function Policy() {
  return (
    <div className="privacy-terms">
      <p className="effective-date">Effective Date: November 16, 2022</p>

      <p>
        DeltaMath (“we” or “DeltaMath”) is committed to protecting the privacy
        of teachers and students. This Privacy Policy will explain (i) what
        information DeltaMath collects from you and why we collect it, (ii) how
        we use and share that information, and (iii) the choices you have,
        including how to access, update and delete your information.
      </p>
      <p>Any material change to these policies will be announced via a banner message displayed on this website.</p>
      <h2>How we Collect and Use Information</h2>
      <p>
        <b>Teacher Registration and Class Setup:</b> We ask for certain
        information when a teacher registers with DeltaMath. Teachers must
        provide a school name and address where they currently teach and also
        their full name and salutation. This information will be shown to
        students when they register so they are confident they are registering
        for the correct class. Teachers must also provide an email address and
        password. The email and password are used for login purposes to protect
        student data that only teachers have access to. The email address may
        also be used to send infrequent updates about DeltaMath. The teachers
        must also provide information about the classes they are teaching. This
        allows the teacher to create assignments on a per class basis and the
        students will select the correct section from a dropdown box when they
        register for the class.
      </p>
      <p>
        <b>Student Registration:</b> Students may only sign up for a DeltaMath
        account with a valid teacher code from a registered DeltaMath teacher.
        The students are given the teacher code and the students are responsible
        for creating their own account. Students must enter their full name,
        email address, password and select the correct class section when
        creating the account. The email addresses are used for a unique login as
        well as password recovery initiated by the student. In order to improve
        privacy, there is an option in the teacher "Tools" that allows email
        addresses to <i>not</i> be used during registration. In this case, the
        teacher could assign non personally identifiable usernames and nicknames
        in order to increase student anonymity. The only users that will be able
        to see the student personally identifiable information are the teachers
        for whom the student has registered. The student's teachers are{" "}
        <i>not</i> able to see their students' passwords, but the teacher is
        able to reset the password of a student in the event that a student has
        forgotten their password.
      </p>
      <p>
        <b>Assignment Data:</b> The primary purpose of DeltaMath is for teachers
        to put up assignments and for students to complete them. Teachers will
        create assignments, specifying relevant math problems for students to
        complete. Teachers have the opportunity to share their assignments with
        all DeltaMath users, but the default is that all assignments created are
        private. Students will complete the assignments and data will be
        collected regarding student progress. This data includes which problems
        where attempted, the time at which each problem was attempted, the
        answers the student submitted and whether they were correct, the
        duration the student was working on each problem, whether each
        assignment was completed on time and the completion percentage for each
        assignment. Some problems have corresponding videos and data is
        collected on whether the student watched the videos and for how long. If
        the teacher decides to assign a "Test Correction" assignment, the
        students are required to enter in their grade on the given test or quiz
        and input which questions they got wrong in order for a customized
        assignment to be made for the student. All of this data is available to
        the student's teacher(s) in order to use the information for student
        accountability purposes or to inform their instruction. The information
        is also available to all future, past and concurrent teachers added by
        the student in order for each teacher to understand their students'
        strengths and weaknesses to the fullest extent.
      </p>
      <p>
        <b>Automatically Collected Data:</b> We automatically collect certain
        technical usage information when you use the DeltaMath Services (“Usage
        Data”). Usage Data includes the information that your web browser or
        mobile application automatically sends to our servers whenever you
        visit. The Usage Data collected in our logs may include information such
        as your web request, Internet Protocol address, operating system,
        browser type, browser language, referring / exit pages and URLs,
        platform type, click history, domain names, landing pages, pages viewed
        and the order of those pages, the amount of time spent on particular
        pages, the date and time of your request, and whether you opened an
        email. Typically, this information is collected through log files, web
        beacons, browser cookies, or other device identifiers that may uniquely
        identify your browser or device. Teachers are able to see the IP
        addresses of their students for any given problem solved in order to
        ensure that multiple accounts are not signing in from the same computer
        possibly indicating one student is signing into another student's
        account to complete work under a false identity. In addition, the
        DeltaMath Services may use third party analytics and bug tracking
        software (including, without limitation, Google Analytics) to collect
        further Usage Data regarding the online usage patterns of our users and
        bugs in our Services. We may combine Usage Data with Personal Data in a
        manner that enables us to trace Usage Data to an individual user. We do
        not permit third party advertising networks or other third parties to
        collect information about your browsing behavior from our website for
        advertising purposes.
      </p>

      <h2>How we Share Your Information</h2>
      <p>
        DeltaMath does not sell or rent any of your, or your child’s, personal
        information to any third party for any purpose - including for
        advertising or marketing purposes. Targeted advertising is not permitted
        on DeltaMath. We use the information we collect from you to provide you
        with the best DeltaMath experience. More specifically, this information
        is used to:
      </p>
      <ul className="share-list">
        <li>
          Provide and improve the Service, for example by developing new
          products and features
        </li>
        <li>Respond to your requests for information or customer support</li>
        <li>
          Customize the Service for you, and improve your experience with it
        </li>
        <li>
          Send you information about new features and DeltaMath products we
          believe you may be interested in
        </li>
      </ul>
      <p>
        We use automatically collected information (described in the
        “Automatically Collected Data” section above) to provide and support our
        Service, and for the additional uses described in this section of our
        Privacy Policy.
      </p>
      <p>
        We do not share personal information with any third parties except in
        the limited circumstances described in this Privacy Policy:
      </p>
      <p>
        <b>Service Providers:</b> Targeted advertising is not permitted on
        DeltaMath. We do work with vendors, service providers, and other
        partners to help us provide the Service by performing tasks on our
        behalf. We may need to share or provide information (including personal
        information) to them to help them perform these business functions, for
        example sending emails on our behalf, database management services,
        database hosting, and security. Generally, these service providers do
        not have the right to use your personal information we share with them
        beyond what is necessary to assist us. Additionally, these service
        providers must adhere to the same standards of data privacy and security
        as those detailed herein, particularly with regard to protected personal
        information.
      </p>
      <p>
        <b>Analytics Services:</b> We use analytics services, including mobile
        analytics software, to help us understand and improve how the Service is
        being used. These services may collect, store and use information in
        order to help us understand things like how often you use the Service,
        the events that occur within the application, usage, performance data,
        and from where the application was downloaded.
      </p>

      <p>
        <b>Aggregated Information and Non-Identifying Information:</b> We may
        share aggregated, non-personally identifiable information publicly,
        including with users, partners or the press in order to, for example,
        demonstrate how DeltaMath is used, spot industry trends, or to provide
        marketing materials for DeltaMath. Any aggregated information shared
        this way will not contain any personal information.
      </p>

      <p>
        <b>Legal Requirements:</b> We may disclose personal information if we
        have a good faith belief that doing so is necessary to comply with the
        law, such as complying with a subpoena or other legal process. We may
        need to disclose personal information where, in good faith, we think it
        is necessary to protect the rights, property, or safety of DeltaMath,
        our employees, our community, or others, or to prevent violations of our
        Terms of Service or other agreements. This includes, without limitation,
        exchanging information with other companies and organizations for fraud
        protection or responding to government requests.
      </p>

      <p>
        <b>Sharing with DeltaMath Companies:</b> Over time, DeltaMath may grow
        and reorganize. We may share your personal information with affiliates
        such as a parent company, subsidiaries, joint venture partners or other
        companies that we control or that are under common control with us, in
        which case we will require those companies to agree to use your personal
        information in a way that is consistent with this Privacy Policy.
      </p>

      <p>
        <b>Change of Control:</b> If we sell, divest or transfer DeltaMath or a
        portion of DeltaMath, we will not transfer Student personal information
        without first giving you the ability to opt-out of the transfer by
        deleting your account, unless the new owner intends to maintain and
        provide the Service as a going concern, and provided that the new owner
        has agreed to data privacy standards no less stringent than our own. We
        may also transfer personal information – under the same conditions – in
        the course of mergers, acquisitions, bankruptcies, dissolutions,
        reorganizations, liquidations, similar transactions or proceedings
        involving all or a portion of our business.
      </p>

      <h2>How we Store and Protect Your Information</h2>
      <p>
        <b>Storage and Processing:</b> Any information collected through the
        Service is stored and processed in the United States. If you use our
        Service outside of the United States, you consent to have your data
        transferred to the United States.
      </p>
      <p>
        <b>Keeping Information Safe:</b> DeltaMath maintains strict
        administrative, technical and physical procedures to protect information
        stored in our servers, which are located in the United States. We use
        highly rated hosting providers with onsite 24-hour security and teams of
        experts dedicated to monitoring network security. Access to information
        is limited through user/password credentials by the administrator of the
        website only. When you enter any information anywhere on the Service, we
        encrypt the transmission of that information using secure socket layer
        technology (SSL/TLS) by default. We ensure passwords are stored and
        transferred securely using encryption, salted hashing and key stretching
        to prevent brute force password cracking.
      </p>
      <p>
        Although we make concerted good faith efforts to maintain the security
        of personal information, and we work hard to ensure the integrity and
        security of our systems, no practices are 100% immune, and we can’t
        guarantee the security of information. Outages, attacks, human error,
        system failure, unauthorized use or other factors may compromise the
        security of user information at any time. If we learn of a security
        breach, we will attempt to notify you electronically (subject to any
        applicable laws) so that you can take appropriate protective steps; for
        example, we may post a notice on our homepage (www.deltamath.com) or
        elsewhere on the Service, and may send an email to you at the email
        address you have provided to us.
      </p>

      <h2>How to Update or Delete Your Information</h2>
      <p>
        <b>Account Information and Settings:</b> Schools and students may update
        account information, such as email, password and name, by signing into
        their account and clicking "Tools". Schools and other website users can
        opt-out of receiving promotional email from us by clicking on the
        "unsubscribe" feature at the bottom of each any email sent to them.
        Teachers may change a password or alter a name of any student account
        who has added the teacher code of that teacher to their student account.
      </p>
      <p>
        If you have any questions about reviewing or modifying account
        information, contact us directly at support@deltamath.com.
      </p>
      <p>
        <b>Deleting Your Account and Information:</b> If you would like to
        delete your account and information from the site, please contact
        support@deltamath.com and your request will be honored within a reasonable
        amount of time. The request should be made from the email address
        associated with the account to ensure it is a legitimate request. If you
        are a student or a parent of a student of an active School account, the
        request must be made by the School as an intermediary in order to give
        the School ample chance to download information from the site that may
        be used for legitimate educational purposes. We may maintain anonymized,
        aggregated data, including usage data, for analytics purposes.
      </p>

      <h2>Children's Privacy</h2>
      <p>
        DeltaMath does not knowingly collect any information from children under
        the age of 13 unless and until the School has obtained appropriate
        parental consent for the student to use the Service. Because DeltaMath
        collects and uses Student Data at the direction of and under the control
        of a School, DeltaMath relies on each School to provide appropriate
        notice to parents of the School's use of third party service providers
        such as DeltaMath, and for the Schools to provide consent, if necessary,
        and authorization for DeltaMath to collect Student Data, as permitted by
        the Children's Online Privacy Protection Act (COPPA). Please contact us
        at support@deltamath.com if you believe we have inadvertently collected
        personal information of a child under 13 without proper consent so that
        we may delete such data as soon as possible.
      </p>

      <h2>Changes and Updates</h2>
      <p>
        This Privacy Policy may be revised periodically and this will be
        reflected in the “Effective Date” at the top of this page. Your
        continued use of the DeltaMath Services following such update
        constitutes your agreement to the revised Privacy Policy.
      </p>
    </div>
  );
}
